













































































import Vue from 'vue'
import Component from 'vue-class-component'

import Content from '@/components/Content.vue'
import Logo from '@/components/Layout/Logo.vue'
import { SubscriptionStatus } from '@/api'

@Component({
  components: {
    Content,
    Logo
  },
  computed: {
    currentUser () {
      return this.$store.state.currentUser
    },

    showBackButton () {
      return this.$store.state.hasMultipleUsers
    },

    showTellAFriendPage () {
      const currentUser = this.$store.state.currentUser
      // Subscription needs to be "active", and project must allow it.
      if (currentUser?.subscription?.subscriptionStatus === SubscriptionStatus.Accepted || currentUser?.subscription?.subscriptionStatus === SubscriptionStatus.Active || currentUser?.subscription?.subscriptionStatus === SubscriptionStatus.Ending) {
        if (currentUser.projectInformation.showTellAFriendPageInPortal === true) {
          return true
        }
      }
      return false
    },

    showCashBackActionPage () {
      const currentUser = this.$store.state.currentUser
      if (currentUser?.subscriptionDetails?.showCashbackPageInPortal === true) {
        return true
      }
      return false
    },

    showCampaignPage () {
      const currentUser = this.$store.state.currentUser
      if (currentUser?.projectInformation?.showCampaignPageInPortal === true) {
        return true
      }
      return false
    },

    subscriptionExpires () {
      const currentUser = this.$store.state.currentUser
      return currentUser && currentUser?.subscriptionDetails?.subscriptionStatus === 'Expires'
    }
  },
  methods: {
    logout () {
      this.$router.push({
        path: '/auth/logout'
      })
    }
  }
})
export default class UserDashboard extends Vue {
  public gotoUsers () {
    this.$router.push({
      name: 'users'
    })
  }

  SubscriptionStatus = SubscriptionStatus;
}
